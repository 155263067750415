import React, { useState } from "react"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import emailJS from "emailjs-com"

const initialContactFormData = {
  from_name: "",
  from_subject: "",
  from_email: "",
  from_phone: "",
  message: "",
}

const Contact = props => {
  const intl = useIntl()
  const [formData, setFormData] = useState(initialContactFormData)
  const [sending, setSending] = useState(false)

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    })
  }

  function sendEmail(e) {
    e.preventDefault()
    setSending(true)
    emailJS
      .sendForm(
        "gmail",
        "template_orLCF45R",
        e.target,
        "user_5eXSlKq0Ax9OgBFTaPeTS"
      )
      .then(
        result => {
          setSending(false)
          setFormData(initialContactFormData)
        },
        error => {
          setSending(false)
        }
      )
  }

  return (
    <>
      <SEO
        title={intl.formatMessage({ id: "contact" })}
        description={intl.formatMessage({ id: "contactDescription" })}
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6 col-xl-4">
            <h1 className="pages-title">
              <FormattedMessage id="contactPageHeader" />
            </h1>
            <form className="contact-form" onSubmit={sendEmail}>
              <label>{intl.formatMessage({ id: "contact_form_name" })}</label>
              <input
                value={formData["from_name"]}
                type="text"
                name="from_name"
                onChange={handleChange}
                required
              />

              <label>
                {intl.formatMessage({ id: "contact_form_subject" })}
              </label>
              <input
                value={formData["from_subject"]}
                type="text"
                name="from_subject"
                onChange={handleChange}
                required
              />

              <label>{intl.formatMessage({ id: "contact_form_email" })}</label>
              <input
                value={formData["from_email"]}
                type="email"
                name="from_email"
                onChange={handleChange}
                required
              />

              <label>{intl.formatMessage({ id: "contact_form_phone" })}</label>
              <input
                value={formData["from_phone"]}
                type="tel"
                name="from_phone"
                onChange={handleChange}
                required
              />

              <label>
                {intl.formatMessage({ id: "contact_form_message" })}
              </label>
              <textarea
                value={formData["message"]}
                name="message"
                onChange={handleChange}
                required
              />

              <input
                type="submit"
                value={intl.formatMessage({ id: "contact_form_send" })}
                disabled={sending}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
